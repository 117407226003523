import { BaseSchema } from 'valibot';
import { useFetcher } from '@remix-run/react';
import { FormEvent } from 'react';
import { SerializeFrom } from '@remix-run/cloudflare';

export default function useSubmitSchema<R extends {}>(
  _schema: BaseSchema<any, any, any>,
  action: string,
): [SerializeFrom<R> | undefined, (e: FormEvent<HTMLFormElement>) => void] {
  const fetcher = useFetcher<R>();

  return [
    fetcher.data,
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      fetcher.submit(e.currentTarget, {
        action,
        method: 'POST',
        navigate: false,
        preventScrollReset: true,
      });
    },
  ];
}
