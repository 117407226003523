import { TenantProvider } from '../../common/TenantPersona';
import { BusinessHoursDay } from '~/lib/model';
import TwtAerialConfig, { TwtAerialType } from '~/tenants/twt/performable/aerial/TwtAerialConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtVideoConfig from '~/tenants/twt/performable/video/TwtVideoConfig';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';

export enum TwtProviderId {
  ALEC_KNIGHT = 1,
  ALEX_CASTRO = 2,
  ALLISON_CARTWRIGHT = 3,
  AMANDA_TERRY = 4,
  ANDREW_LANKES = 5,
  MICHELLE_NOLAN = 6,
  BRYAN_MURPHY = 7,
  CALEB_RUCKEL = 8,
  DANA_RASMUSSEN = 9,
  JEFF_LOFTIN = 10,
  KYLE_GIRGUS = 11,
  LAURA_DUNCAN = 12,
  LINAS_MILAVICKAS = 13,
  LINN_RIVERS = 14,
  ASHLEY_MATHEWS = 16,
  PHILIP_ROGERS = 17,
  RICKY_LANIER = 18,
  TRAVIS_BAKER = 19,
  TRENT_HEADY = 20,
  DEAN_JANKA = 21,
  //NICK_RUCKEL = 22
  //PARIS_HOLLEY = 23,
}

export interface TwtProviderStaticData {
  days?: Partial<
    Record<
      BusinessHoursDay,
      {
        schedule_end?: TTime24;
        travel_end?: TTime24;
        photo_daily?: number;
        confirm?: true;
      }
    >
  >;

  fill?: number;
  elevated?: true;
  postal_excludes?: string[];

  travel_start?: TTime24;
  travel_end?: TTime24;
  travel_end_ideal?: TTime24;
  schedule_start?: TTime24;
  schedule_end?: TTime24;

  rush_end_one?: TTime24;
  rush_end_one_twilight?: TTime24;
  rush_end_two?: TTime24;
  rush_weekend?: true | 'warning';
  rush_avoid?: true;

  photo_daily?: number;
  photo_onsite_0_1000?: number;
  photo_onsite_1001_2000?: number;
  photo_onsite_2001_3000?: number;
  photo_onsite_3001_4000?: number;
  photo_onsite_4001_5000?: number;
  photo_onsite_5001_6000?: number;
  photo_onsite_every_1000_after_6000?: number;

  scan_daily?: number;
  scan_onsite_0_1000?: number;
  scan_onsite_1001_2000?: number;
  scan_onsite_2001_3000?: number;
  scan_onsite_3001_4000?: number;
  scan_onsite_4001_5000?: number;
  scan_onsite_5001_6000?: number;
  scan_onsite_every_1000_after_6000?: number;

  highlights_onsite?: number;

  aerial_onsite_mini?: number;
  aerial_onsite_full?: number;
  aerial_onsite_video?: number;
  aerial_onsite_photo_video?: number;
  aerial_confirm_plus_interior?: true;
  aerial_confirm_plus_interior_photos?: true;

  aerial_end_video?: TTime24;
  aerial_end_photo?: TTime24;

  video_onsite_0_2000?: number;
  video_onsite_2001_3000?: number;
  video_onsite_over_3000?: number;
  video_confirm?: boolean;

  reel_onsite?: number;

  cameo_onsite?: number;

  twilight_days?: BusinessHoursDay[];
  twilight_days_ideal?: BusinessHoursDay[];
  twilight_days_confirm?: BusinessHoursDay[];
  twilight_postals_includes?: string[];
  twilight_postals_excludes?: string[];
  twilight_weekly_ideal?: number;
  twilight_weekly_max?: number;
  twilight_daily_shoots?: number;
  twilight_delay?: TTime24;
  twilight_distance?: number;
  twilight_end?: TTime24;

  scan_mppro2?: true;
  scan_ricoh?: true;
  scan_mpcam?: true;
}

export default class TwtProvider extends TenantProvider<TwtProviderStaticData> {
  canPerform(context: TwtOrderContext, job: TwtOrderContext['jobs'][0]): true | string {
    if (
      context.buyer.matterport_camera_only &&
      job.performable_id === '3d' &&
      !this.config.scan_mpcam &&
      !this.config.scan_mppro2
    ) {
      return 'This provider does not have a Matterport Camera';
    }

    if (job.performable_id === 'aerial') {
      if (job.metadata.type === TwtAerialType.PHOTO_MINI && !this.config.aerial_onsite_mini) {
        return 'This provider does not do mini aerials.';
      }

      if (job.metadata.type === TwtAerialType.PHOTO_FULL && !this.config.aerial_onsite_full) {
        return 'This provider does not do full aerials.';
      }

      if (job.metadata.type === TwtAerialType.VIDEO && !this.config.aerial_onsite_video) {
        return 'This provider does not do aerial videos.';
      }

      if (job.metadata.type === TwtAerialType.PHOTO_VIDEO && !this.config.aerial_onsite_photo_video) {
        return 'This provider does not do aerial photo/video combos.';
      }
    }

    if (job.performable_id === 'video') {
      if (
        !this.config.video_onsite_0_2000 &&
        !this.config.video_onsite_2001_3000 &&
        !this.config.video_onsite_over_3000
      ) {
        return 'This provider does not do property videos.';
      }

      if (job.metadata.voiceover && !this.config.cameo_onsite) {
        return 'This provider does not do cameos.';
      }
    }

    if (job.performable_id === 'reel' && !this.config.reel_onsite) {
      return 'This provider does not do social reels.';
    }

    if (job.performable_id === 'photo') {
      if (job.metadata.twilight && !this.config.twilight_days && !this.config.twilight_weekly_max) {
        return 'This provider does not do twilight shoots.';
      }

      if (job.metadata.highlights && !this.config.highlights_onsite) {
        return 'This provider does not do highlights.';
      }
    }

    return true;
  }

  get performableIds(): string[] {
    return [
      TwtAerialConfig.id,
      TwtFloorplanConfig.id,
      TwtMatterportConfig.id,
      TwtPhotoConfig.id,
      TwtReelConfig.id,
      TwtVideoConfig.id,
    ];
  }
}
