import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { PersonaType } from '~/tenants/common/TenantPersona';

export default function TwtMatterportForm({ create, persona }: TenantPerformableFormProps<TwtOrderContext>) {
  return (
    <div className="space-y-4">
      {persona === PersonaType.CUSTOMER && create && (
        <iframe
          src="https://my.matterport.com/show/?m=mMqN2oQ5dPa"
          className="w-full h-[400px] rounded-theme overflow-hidden"
        />
      )}
      <FormHorizontal label="Floors">
        <ZodFieldInput type="number" name="floors" />
      </FormHorizontal>
    </div>
  );
}
