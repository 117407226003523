import MpxMatterportConfig, { MpxMatterportDevice } from '~/tenants/mpx/performable/matterport/MpxMatterportConfig';
import MpxJob from '~/tenants/mpx/model/MpxJob';
import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { MpxOrderType } from '~/tenants/mpx/model/MpxOrder';
import {
  TenantSubscriptionMetadata,
  TenantSubscriptionStatus,
  TenantSubscriptionType,
} from '~/tenants/common/TenantPersona';
import { DateTime } from 'luxon';
import { format_money } from '~/components/Money';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

export default class MpxMatterportJob extends MpxJob<typeof MpxMatterportConfig> {
  get performable() {
    return MpxMatterportConfig;
  }

  onsite(): number {
    return 30;
  }

  get configurable(): boolean {
    return true;
  }

  get multiple(): boolean {
    return this.order.metadata.type === MpxOrderType.MULTI_FAMILY;
  }

  defaultValue(): ZodVersionedMetadata<(typeof MpxMatterportConfig)['schema']> {
    if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY && this.order.metadata.units.length > 0) {
      return {
        version: FIRST_VERSION_TIMESTAMP,
        type: 'unit',
        unit: this.order.metadata.units[0].id,
      };
    }

    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'sqft',
      device: MpxMatterportDevice.PRO2,
    };
  }

  expenseLines(): TenantJobLine[] {
    const lines = super.expenseLines();

    let perSqft = new Big(0);
    let sqft = 'sqft' in this.order.metadata ? this.order.metadata.sqft : 0;
    let minimum = new Big(0);

    if (this.order.buyer.matterport_expense_minimum) {
      minimum = new Big(this.order.buyer.matterport_expense_minimum);
    } else if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      perSqft = new Big('0.04');
      minimum = new Big(70);
    } else if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      if (this.order.metadata.units.length === 1) {
        if (this.order.metadata.units[0].sqft >= 2000 || this.order.metadata.units[0].beds >= 3) {
          minimum = new Big(70);
        } else {
          minimum = new Big(60);
        }
      } else if (this.order.metadata.units.some((u) => u.beds >= 3 || u.sqft >= 2000)) {
        minimum = new Big(60);
      } else {
        minimum = new Big(50);
      }

      sqft = this.order.metadata.units.reduce((sum, unit) => sum + unit.sqft, 0);
    } else if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      minimum = new Big(70);

      if (this.metadata.device === MpxMatterportDevice.PRO3) {
        perSqft = new Big('0.02');

        if (this.metadata.exterior) {
          lines.push({
            id: 'exterior',
            description: 'Matterport Pro3 (Exterior)',
            amount: new Big(30),
            taxable: true,
          });
        }
      } else {
        perSqft = new Big('0.03');
      }
    }

    if (sqft > 0 && perSqft.gt(0)) {
      let total = perSqft.times(sqft);
      let description = `Matterport (${sqft} sqft @ ${format_money(perSqft.toFixed(2))}/sqft)`;

      if (total.lt(minimum)) {
        total = minimum;
        description = 'Matterport Minimum';
      }

      if (this.order.metadata.type === MpxOrderType.COMMERCIAL && total.gt(600)) {
        // max commercial day rate
        total = new Big(600);
      }

      lines.push({
        id: 'sqft',
        description,
        amount: total,
      });
    }

    return lines;
  }

  revenueLines() {
    const lines: TenantJobLine[] = [];

    let amount = new Big(0);
    let description = 'Matterport';

    if (this.metadata.google) {
      lines.push({
        id: 'google',
        description: 'Publish as Google Virtual Tour',
        amount: new Big(49),
        taxable: true,
      });
    }

    if (this.metadata.staging) {
      lines.push({
        id: 'staging',
        description: `Virtual Staging (${this.metadata.staging})`,
        amount: new Big(this.metadata.staging).times(125),
        taxable: true,
      });
    }

    if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      if (this.metadata.type === 'unit') {
        for (const unit of this.order.metadata.units) {
          if (unit.id === this.metadata.unit) {
            if (unit.beds >= 5 || unit.sqft >= 3500) {
              amount = new Big(299);
            } else if (unit.beds >= 3) {
              amount = new Big(239);
            } else {
              amount = new Big(179);
            }

            if (unit.sqft > 3500) {
              const overage = Math.ceil((unit.sqft - 3500) / 1000);

              amount = amount.plus(new Big(overage).times(50));
            }

            description = `Matterport: Unit #${unit.number}`;

            break;
          }
        }
      }

      if (this.metadata.exterior) {
        lines.push({
          id: 'community',
          description: 'Community 360 Tour',
          amount: new Big(225),
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      const bundled = this.order.jobs.some((j) => j.performable_id === 'photos');

      let bundle = new Big(0);

      if (this.order.metadata.sqft < 2000) {
        amount = new Big(239);

        if (bundled) {
          bundle = new Big(-55);
        }
      } else {
        amount = new Big(299);

        if (this.order.metadata.sqft > 3499) {
          const overage = Math.ceil((this.order.metadata.sqft - 3499) / 1000);

          amount = amount.plus(new Big(overage).times(50));

          if (bundled) {
            bundle = new Big(-180);
          }
        } else if (bundled) {
          bundle = new Big(-65);
        }
      }

      if (bundle.abs().gt(0)) {
        lines.push({
          id: 'bundle',
          description: 'Photos + 3D Bundle Discount',
          amount: bundle,
          discount: true,
        });
      }

      if (this.metadata.floorplan) {
        lines.push({
          id: 'floorplan',
          description: 'Schematic Floorplans',
          amount: new Big(35),
          taxable: true,
        });
      }

      if (this.metadata.video) {
        lines.push({
          id: 'video',
          description: 'Walkthrough Video',
          amount: new Big(49),
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.ARCHITECTURAL) {
      if (this.order.metadata.sqft < 1000) {
        amount = new Big(349);
      } else if (this.order.metadata.sqft < 2000) {
        amount = new Big(539);
      } else {
        amount = new Big(799);
      }

      if (this.metadata.cad) {
        lines.push({
          id: 'cad',
          description: 'CAD Assets',
          amount: new Big(599),
        });
      }

      if (this.metadata.floorplan) {
        lines.push({
          id: 'floorplan',
          description: 'Floorplan',
          amount: new Big(this.order.metadata.sqft < 10000 ? 349 : 749),
          taxable: true,
        });
      }
    } else if (this.order.metadata.type === MpxOrderType.INSURANCE) {
      if (this.order.metadata.sqft < 1000) {
        amount = new Big(249);
      } else if (this.metadata.trueplan) {
        if (this.order.metadata.sqft < 4000) {
          amount = new Big(349);
        } else if (this.order.metadata.sqft < 10000) {
          amount = new Big(599);
        } else {
          amount = new Big(999);
        }
      } else {
        if (this.order.metadata.sqft < 2000) {
          amount = new Big(339);
        } else {
          amount = new Big(399);
        }

        if (this.order.metadata.sqft > 3499) {
          const overage = Math.ceil((this.order.metadata.sqft - 3499) / 1000);

          amount = amount.plus(new Big(overage).times(50));
        }
      }
    } else if ('sqft' in this.order.metadata) {
      let minimum = new Big(0);
      let sqft = new Big(0.07);

      if (this.order.metadata.type === MpxOrderType.BUILDERS) {
        minimum = new Big(195);
        sqft = new Big(0.09);
      } else if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
        minimum = new Big(250);

        if (this.order.metadata.sqft < 20000) {
          sqft = new Big(0.08);
        }
      }

      const calculated = sqft.times(this.order.metadata.sqft);
      description = `Matterport (${format_money(sqft.toFixed(2))}/sqft)`;

      if (minimum.gte(calculated)) {
        amount = minimum;
        description = `Matterport (Minimum)`;
      } else {
        amount = calculated;
      }

      if (this.metadata.exterior) {
        if (this.metadata.device === MpxMatterportDevice.PRO3) {
          lines.push({
            id: 'pano',
            description: 'Matterport Pro3 (Exterior)',
            amount: new Big(500),
            taxable: true,
            estimate: true,
          });

          description = 'Matterport Pro3 (Interior)';
        } else {
          lines.push({
            id: 'pano',
            description: 'Exterior 360 Images',
            amount: new Big(0),
          });
        }
      }
    }

    if (amount.gt(0)) {
      lines.push({
        id: 'matterport',
        description,
        amount,
        taxable: true,
      });
    }

    /*
    if (this.metadata.override_sqft) {
      if (this.order.metadata.type === MpxOrderType.BUILDERS) {
        lines.push({
          id: 'override',
          description: 'Matterport & 2D Floor Plan',
          amount: new Big(this.metadata.override_flat ?? 0).times(this.order.metadata.sqft),
          taxable: true,
        });
      }
    } else if (this.metadata.override_flat) {
      lines.push({
        id: 'override',
        description: 'Matterport & 2D Floor Plan',
        amount: new Big(this.metadata.override_flat),
        taxable: true,
      });
    } else if (this.order.metadata.type === MpxOrderType.COMMERCIAL) {
      let rate = '0.02';
      let name = 'CRE Matterport (Standard)';

      const zips: Record<string, string> = {
        '30260': '0.02',
        '30560': '0.05',
        '30360': '0.035',
        '30860': '0.08',
        '30760': '0.07',
      };

      if (this.order.address?.zip) {
        if (zips[this.order.address.zip]) {
          rate = zips[this.order.address.zip];
          name = `CRE Matterport (${this.order.address.zip})`;
        }
      }

      lines.push({
        id: 'sqft',
        description: name,
        amount: new Big(rate).times(this.order.metadata.sqft),
        taxable: true,
      });
    } else if (this.order.metadata.type === MpxOrderType.BUILDERS) {
      lines.push({
        id: 'sqft',
        description: 'Matterport',
        amount: new Big(this.order.buyer.matterport_sqft ?? '0.08').times(this.order.metadata.sqft),
        taxable: true,
      });
    } else if (this.order.metadata.type === MpxOrderType.MULTI_FAMILY) {
      // if (this.order.metadata.size === 'large') {
      //   lines.push({
      //     id: 'large',
      //     description: 'Matterport & 2D Floor Plan',
      //     amount: new Big(299),
      //     taxable: true,
      //   });
      // } else {
      //   lines.push({
      //     id: 'flat',
      //     description: 'Matterport & 2D Floor Plan',
      //     amount: new Big(!this.order.metadata.bedrooms || this.order.metadata.bedrooms <= 2 ? 149 : 239),
      //     taxable: true,
      //   });
      // }
    } else if (this.order.metadata.type === MpxOrderType.RESIDENTIAL) {
      if (this.order.metadata.sqft <= 2000) {
        lines.push({
          id: 'flat',
          description: 'Matterport',
          amount: new Big(239),
          taxable: true,
        });
      } else {
        let rate = '0.12';
        let name = 'Matterport';

        const zips: Record<string, string> = {
          '30760': '0.07',
          '31060': '0.1',
          '31260': '0.12',
        };

        if (this.order.address?.zip) {
          if (zips[this.order.address.zip]) {
            rate = zips[this.order.address.zip];
            name = `Matterport (${this.order.address.zip})`;
          }
        }

        lines.push({
          id: 'sqft',
          description: name,
          amount: new Big(rate).times(this.order.metadata.sqft),
          taxable: true,
        });
      }
    }

     */

    return lines;
  }

  subscription(): TenantSubscriptionMetadata {
    if (!this.metadata.url) {
      //throw new Error(`Cannot create subscription for Matterport job (${this.id}) without URL.`);
    }

    return {
      type: TenantSubscriptionType.MATTERPORT,
      name: this.order.address
        ? `${this.order.address.line1}, ${this.order.address.city}, ${this.order.address.state} ${this.order.address.zip}`
        : 'Matterport',
      status: TenantSubscriptionStatus.ACTIVE,
      created: DateTime.now().toJSDate().toISOString(),
      order_id: this.order.id,
      job_id: this.id,
      months: 12,
      cost: 24,
      expires: DateTime.now().plus({ months: 6 }).toJSDate().toISOString(),
      renew: false,
      payments: {},
      url: this.metadata.url ?? 'https://example.com',
    };
  }
}
