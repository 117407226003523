import { TenantState } from './TenantState';
import { NotificationType } from '~/lib/enum';
import { addressToArea, addressToStreet, DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { TenantJob } from '~/tenants/common/TenantJob';
import { ExtractTenantOrderContextPerformable, TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { TenantConfig } from '~/tenants/common/registry';
import TenantEmailContext, {
  TenantEmailAppointmentContext,
  TenantEmailOrderContext,
} from '~/tenants/common/TenantEmailContext';
import { ReactNode } from 'react';

export enum TenantOrderEmailTrigger {
  ORDER_COMPLETE = 'ORDER_COMPLETE',
  APPOINTMENT_LOCKED = 'APPOINTMENT_LOCKED',
}

export type TenantOrderEmail<O extends TenantOrderContext = TenantOrderContext> = {
  id: string;
  once?: boolean;
} & (
  | {
      trigger: TenantOrderEmailTrigger.ORDER_COMPLETE;
      template: <C extends TenantEmailOrderContext<O['metadata'], ExtractTenantOrderContextPerformable<O>>>(
        context: C,
      ) => ReactNode;
    }
  | {
      trigger: TenantOrderEmailTrigger.APPOINTMENT_LOCKED;
      template: <C extends TenantEmailAppointmentContext<O['metadata'], ExtractTenantOrderContextPerformable<O>>>(
        context: C,
      ) => ReactNode;
    }
);

export abstract class TenantOrder<
  O extends TenantOrderContext = TenantOrderContext,
  J extends TenantJob<O> = TenantJob<O>,
> {
  constructor(
    public readonly tenant: TenantConfig,
    public readonly context: O,
  ) {}

  get emails(): TenantOrderEmail<O>[] {
    return [];
  }

  get jobs(): J[] {
    // TODO: how to make typesafe?
    // @ts-ignore
    return this.context.jobs.map(
      (job) => new this.tenant.performables[job.performable_id].job(this.context, job.id, job.metadata),
    );
  }

  sendNotification(type: NotificationType): { attachments: string[] } {
    return { attachments: [] };
  }

  get location() {
    const location = this.tenant.locations.find((f) => f.slug === this.context.location);

    if (!location) {
      throw new Error(`Location ${this.context.location} not found`);
    }

    return location;
  }

  info(): Array<DisplayData> {
    const info: DisplayData[] = [];

    if (this.context.address) {
      info.push({
        name: 'Address',
        value: `${addressToStreet(this.context.address)}, ${addressToArea(this.context.address)}`,
      });
    }

    if (this.context.provider) {
      const provider = this.tenant.providers[this.context.provider.id];

      info.push({
        name: 'Preferred Provider',
        value: `${provider.first} ${provider.last}`,
        schedule: true
      });
    }

    return info;
  }

  revenueLines(): TenantOrderLine[] {
    let lines: TenantOrderLine[] = [];

    for (const job of this.jobs) {
      lines = [...lines, ...job.revenueLines().map((line) => ({ ...line, job_id: job.id }))];
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    let lines: TenantOrderLine[] = [];

    for (const job of this.jobs) {
      lines = [...lines, ...job.expenseLines().map((line) => ({ ...line, job_id: job.id }))];
    }

    return lines;
  }

  revenue(): Big {
    return this.revenueLines().reduce((sum, line) => sum.plus(line.amount), Big(0));
  }

  expense(): Big {
    return this.expenseLines().reduce((sum, line) => sum.plus(line.amount), Big(0));
  }

  states(): TenantState[] {
    return [];
  }

  get hasAppointments() {
    return this.context.appointments.length > 0;
  }

  get hasActiveAppointments() {
    return this.context.appointments.filter((a) => !a.canceled).length > 0;
  }
}
