import { ProviderStaticConfig } from '~/lib/model';
import { MpxProviderId, MpxProviderStaticData } from '~/tenants/mpx/model/MpxProvider';

export enum MpxLocation {
  DALLAS = 'DFW',
  ELPASO = 'ELP',
}

export const MPX_PROVIDER_DATA: Record<MpxProviderId, MpxProviderStaticData & ProviderStaticConfig> = {
  [MpxProviderId.EMILY_VILLAGRANA]: {
    first: 'Emily',
    last: 'Villagrana',
    equipment_mppro2: true,
    equipment_mppro3: true,
    color: '#FF0000',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    email: 'provider@parisholley.com',
    start_address: {
      line1: '112 Broken Bow Drive',
      city: 'Gunter',
      state: 'TX',
      zip: '75058',
      long: -96.7329263,
      lat: 33.4738405,
    },
  },
  [MpxProviderId.MICHAEL_SCHULTE]: {
    first: 'Michael',
    last: 'Schulte',
    equipment_mppro2: true,
    equipment_mppro3: true,
    headshot: 'avatar.jpg',
    color: '#00FF00',
    phone: '+15159749848',
    email: 'provider@parisholley.com',
    start_address: {
      line1: '1006 Callahan Avenue',
      city: 'Conroe',
      state: 'TX',
      zip: '77301',
      long: -95.4672765,
      lat: 30.3210232,
    },
  },
  [MpxProviderId.CHRISTOPHER_COOK]: {
    first: 'Christopher',
    last: 'Cook',
    equipment_mppro2: true,
    email: 'provider@parisholley.com',
    phone: '+15159749848',
    headshot: 'avatar.jpg',
    color: '#0000FF',
    start_address: {
      line1: '664 Peacock Lane',
      city: 'New Braunfels',
      state: 'TX',
      zip: '78130',
      long: -98.1414313,
      lat: 29.675644,
    },
  },
};
