import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function TwtCustomerForm() {
  return (
    <FormGroup>
      <FormHorizontal name="notes" label="Notes">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>
      <FormHorizontal name="announcement_send" label="Send Announcement Email">
        <ZodFieldCheckbox name="announcement_send" />
      </FormHorizontal>
      <FormHorizontal name="matterport_discount_dollars" label="Matterport Discount (Dollars)">
        <ZodFieldInput type="number" name="matterport_discount_dollars" />
      </FormHorizontal>
      <FormHorizontal name="discount_percentage" label="Overall Discount (Excluding Twilight) Percentage">
        <ZodFieldInput type="number" name="discount_percentage" />
      </FormHorizontal>
      <FormHorizontal name="matterport_transfer" label="Matterport Transfer">
        <ZodFieldCheckbox name="matterport_transfer" />
      </FormHorizontal>
      <FormHorizontal name="matterport_camera_only" label="Matterport Camera Required">
        <ZodFieldCheckbox name="matterport_camera_only" />
      </FormHorizontal>
      <FormHorizontal name="local_life" label="Local Life">
        <ZodFieldCheckbox name="local_life" />
      </FormHorizontal>
      {/*<FormHorizontal name="first_hour_photo_revenue" label="First Hour Photo Revenue">*/}
      {/*  <ZodFieldInput name="first_hour_photo_revenue" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="first_hour_photo_expense" label="First Hour Photo Expense">*/}
      {/*  <ZodFieldInput name="first_hour_photo_expense" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="first_hour_interior_revenue" label="First Hour Interior Video Revenue">*/}
      {/*  <ZodFieldInput name="first_hour_interior_revenue" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="first_hour_interior_expense" label="First Hour Interior Video Expense">*/}
      {/*  <ZodFieldInput name="first_hour_interior_expense" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="aerial_photo_revenue" label="Aerial Photo Only Revenue">*/}
      {/*  <ZodFieldInput name="aerial_photo_revenue" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="aerial_video_revenue" label="Aerial Video Only Revenue">*/}
      {/*  <ZodFieldInput name="aerial_video_revenue" />*/}
      {/*</FormHorizontal>*/}
      {/*<FormHorizontal name="aerial_photo_video_revenue" label="Aerial Photo + Video Revenue">*/}
      {/*  <ZodFieldInput name="aerial_photo_video_revenue" />*/}
      {/*</FormHorizontal>*/}
    </FormGroup>
  );
}
