import React, { useEffect, useState } from 'react';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { PackageFormNested, usePackageFormContext } from '~/components/performable/PackageForm';
import { TenantPackageInclude } from '~/tenants/common/package';
import TwtPhotoConfig, { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { twt_calculate_photo_images } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { TwtOrderContext, TwtOrderType } from '~/tenants/twt/model/TwtOrder';
import TwtFloorplanUpgrade from '~/tenants/twt/performable/floorplan/TwtFloorplanUpgrade';
import TwtPhotosAddOns from '~/tenants/twt/performable/photo/TwtPhotosAddOns';
import { PersonaType } from '~/tenants/common/TenantPersona';

function TwtPackageCommunityHidden(props: { enable: boolean }) {
  const [_name, value, setValue] = useZodFormFieldSingle('community');

  useEffect(() => {
    if (props.enable.toString() !== value) {
      setValue(props.enable.toString());
    }
  }, [props.enable]);

  return <ZodFieldHidden name="community" value={value} />;
}

export function TwtPackageCommunityAddOn(props: { aerial?: boolean }) {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6">
      <div className="rounded-lg overflow-hidden flex-shrink-0">
        <LegacyResponsiveImage src="card/public/community.jpg" width={200} />
      </div>
      <div className="space-y-4">
        {/*<PackageFormNested performable={TwtPhotographyConfig}>*/}
        {/*  <TwtPhotosCommunityCustom />*/}
        {/*  <TwtPhotosCommunityLicense />*/}
        {/*</PackageFormNested>*/}
        {/*{props.aerial && (*/}
        {/*  <PackageFormNested performable={TwtAerialConfig}>*/}
        {/*    <div>*/}
        {/*      <div className="flex items-center leading-none select-none">*/}
        {/*        <div className="w-8 flex-shrink-0">*/}
        {/*          <ZodFieldCheckbox id="aerial" name="community" />*/}
        {/*        </div>*/}
        {/*        <label className="text-base font-medium cursor-pointer w-full" htmlFor="aerial">*/}
        {/*          Aerial Community Photos*/}
        {/*        </label>*/}
        {/*        <p className="font-bold text-base">*/}
        {/*          <Money>60</Money>*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div className="pl-8 pt-2 max-sm:pr-4">*/}
        {/*        <p className="text-sm text-gray-600">*/}
        {/*          A high resolution example of directed shots that showcase your desired, local sites.*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </PackageFormNested>*/}
        {/*)}*/}
      </div>
    </div>
  );
}

export function TwtPackagePhotoInclude({ image = 'tenant/twt/residential/1.jpg' }: { image?: string }) {
  const context = usePackageFormContext<TwtOrderContext>();

  let photos = twt_calculate_photo_images(context.orderContext.metadata.sqft ?? 0);

  if ([TwtOrderType.LONG_TERM_RENTAL, TwtOrderType.SHORT_TERM_RENTAL].includes(context.orderContext.metadata.type)) {
    photos = 20;
  }

  return (
    <TenantPackageInclude name={`${photos} Photo Tour`} image={image}>
      {TwtPhotoConfig.short}
    </TenantPackageInclude>
  );
}

export function TwtPackageFloorplanInclude() {
  return (
    <TenantPackageInclude name="Standard 2D Floorplan" image="tenant/twt/floorplan/standard2.png">
      A fully colored floor plan with room names and dimensions
    </TenantPackageInclude>
  );
}

export function TwtPackage3dInclude() {
  return (
    <TenantPackageInclude name={Twt3dConfig.name} image="tenant/twt/matterport/1.jpg">
      {Twt3dConfig.short}
    </TenantPackageInclude>
  );
}

export function TwtPackageFloorplanAddOns() {
  return (
    <PackageFormNested performable={TwtFloorplanConfig}>
      <TwtFloorplanUpgrade persona={PersonaType.CUSTOMER} />
    </PackageFormNested>
  );
}

export function TwtPackagePhotoAddOns(props: { type: TwtOrderType }) {
  return (
    <PackageFormNested performable={TwtPhotoConfig}>
      <ZodFieldHidden name="type" value={TwtPhotoType.INTERIOR_EXTERIOR} />
      <TwtPhotosAddOns persona={PersonaType.CUSTOMER} type={TwtPhotoType.INTERIOR_EXTERIOR} order={props.type} />
    </PackageFormNested>
  );
}
