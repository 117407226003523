import { FormHorizontal } from '~/components/form/layout';
import FormGroup from '~/components/form/FormGroup';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';

export default function TwtMatterportSubmit() {
  return (
    <FormGroup>
      https://support.matterport.com/s/article/How-To-Publicly-Share-a-Space?language=en_US#Getting-the-Showcase-Link
      <FormHorizontal label="Branded URL">
        <ValibotFieldInput name="branded_url" />
      </FormHorizontal>
      <FormHorizontal label="Unbranded URL">
        <ValibotFieldInput name="unbranded_url" />
      </FormHorizontal>
    </FormGroup>
  );
}
