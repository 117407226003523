import { BusinessHoursDay, ProviderStaticConfig } from '~/lib/model';
import { TwtProviderId, TwtProviderStaticData } from '~/tenants/twt/model/TwtProvider';

export const TWT_PROVIDER_DATA: Record<TwtProviderId, TwtProviderStaticData & ProviderStaticConfig> = {
  [TwtProviderId.ALEC_KNIGHT]: {
    hidden: true,
    email: 'alecknightphoto@icloud.com',
    first: 'Alec',
    last: 'Knight',
    headshot: 'tenant/twt/provider/alec.jpg',
    phone: '+17372109900',
    start_address: {
      line1: '801 C-Bar Ranch Trail',
      city: 'Cedar Park',
      state: 'TX',
      zip: '78613',
      long: -97.811139,
      lat: 30.523366,
      line2: '#4014',
    },

    schedule_start: '10:00',

    rush_end_one: '15:30',
    rush_weekend: true,

    photo_daily: 5,
    photo_onsite_0_1000: 45,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 60,
    photo_onsite_3001_4000: 75,
    photo_onsite_4001_5000: 90,
    photo_onsite_5001_6000: 105,
    photo_onsite_every_1000_after_6000: 15,

    scan_daily: 3,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 105,
    scan_onsite_every_1000_after_6000: 15,

    twilight_weekly_max: 2,

    highlights_onsite: 30,

    aerial_onsite_mini: 30,
    aerial_onsite_full: 30,
    aerial_onsite_video: 30,
    aerial_onsite_photo_video: 45,

    video_onsite_0_2000: 60,
    video_onsite_2001_3000: 75,
    video_onsite_over_3000: 90,
  },
  [TwtProviderId.ALEX_CASTRO]: {
    hidden: false,
    email: 'Alex_castro4347@icloud.com',
    first: 'Alex',
    last: 'Castro',
    phone: '+16198291632',
    headshot: 'tenant/twt/provider/alex.jpg',
    start_address: {
      line1: '12011 Bell Avenue',
      city: 'Austin',
      state: 'TX',
      zip: '78727',
      long: -97.74901559999999,
      lat: 30.4256677,
    },

    schedule_start: '09:00',

    photo_daily: 3,
    photo_onsite_0_1000: 60,
    photo_onsite_1001_2000: 60,
    photo_onsite_2001_3000: 60,
    photo_onsite_3001_4000: 75,
    photo_onsite_4001_5000: 75,
    photo_onsite_5001_6000: 120,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '16:30',
    rush_weekend: 'warning',

    scan_daily: 3,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 60,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 75,
    scan_onsite_5001_6000: 75,
  },
  [TwtProviderId.ALLISON_CARTWRIGHT]: {
    hidden: true,
    email: 'allison.cartwright@gmail.com',
    first: 'Allison',
    last: 'Cartwright',
    headshot: 'tenant/twt/provider/allison.jpg',
    phone: '+15125077612',
    start_address: {
      line1: '3608 Pennsylvania Avenue',
      city: 'Austin',
      state: 'TX',
      zip: '78721',
      long: -97.694009,
      lat: 30.276162,
    },

    schedule_start: '09:00',
    photo_daily: 4,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 45,
    photo_onsite_3001_4000: 60,
    photo_onsite_4001_5000: 90,
    photo_onsite_5001_6000: 90,
    photo_onsite_every_1000_after_6000: 15,

    scan_daily: 4,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 90,

    highlights_onsite: 30,
  },
  [TwtProviderId.AMANDA_TERRY]: {
    hidden: true,
    email: 'amanda@twisttours.com',
    first: 'Amanda',
    last: 'Terry',
    headshot: 'tenant/twt/provider/amanda.jpg',
    phone: '+15127759287',
    start_address: {
      line1: '320 East White Avenue',
      city: 'San Antonio',
      state: 'TX',
      zip: '78214',
      long: -98.4874892,
      lat: 29.36490959999999,
    },

    schedule_start: '10:00',

    photo_daily: 5,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 60,
    photo_onsite_5001_6000: 60,
    photo_onsite_every_1000_after_6000: 15,

    scan_daily: 3,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 30,
    scan_onsite_3001_4000: 30,
    scan_onsite_4001_5000: 45,
    scan_onsite_5001_6000: 60,
    scan_onsite_every_1000_after_6000: 15,

    twilight_weekly_max: 1,

    highlights_onsite: 30,
  },
  [TwtProviderId.ANDREW_LANKES]: {
    hidden: true,
    email: 'andrewlankes@yahoo.com',
    first: 'Andrew',
    last: 'Lankes',
    phone: '+15122949910',
    headshot: 'tenant/twt/provider/andrew.jpg',
    start_address: {
      line1: '400 Genard Street',
      city: 'Austin',
      state: 'TX',
      zip: '78751',
      long: -97.72588189999999,
      lat: 30.3186832,
    },

    schedule_start: '09:30',

    photo_daily: 6,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 45,
    photo_onsite_5001_6000: 60,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '17:00',

    twilight_weekly_max: 1,
    twilight_days: [
      BusinessHoursDay.Monday,
      BusinessHoursDay.Tuesday,
      BusinessHoursDay.Wednesday,
      BusinessHoursDay.Thursday,
      BusinessHoursDay.Friday,
    ],
    twilight_postals_includes: [
      '78751',
      '78752',
      '78753',
      '78754',
      '78755',
      '78756',
      '78757',
      '78758',
      '78759',
      '78721',
      '78722',
      '78723',
      '78727',
      '78731',
      '78660',
    ],

    scan_daily: 6,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 75,
    scan_onsite_5001_6000: 90,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,
    aerial_onsite_mini: 30,
    aerial_onsite_full: 30,
  },
  [TwtProviderId.ASHLEY_MATHEWS]: {
    hidden: true,
    email: 'ashleyredphotography@gmail.com',
    first: 'Ashley',
    last: 'Matthews',
    phone: '+18325150225',
    headshot: 'tenant/twt/provider/ashred.jpg',
    start_address: {
      line1: '1405 Gorman',
      city: 'San Antonio',
      state: 'TX',
      zip: '78202',
      long: -98.45206739999999,
      lat: 29.4271954,
    },
    schedule_start: '09:30',

    photo_daily: 4,
    photo_onsite_0_1000: 45,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 75,
    photo_onsite_3001_4000: 90,
    photo_onsite_4001_5000: 120,
    photo_onsite_5001_6000: 150,
    photo_onsite_every_1000_after_6000: 15,

    rush_avoid: true,

    twilight_days: [BusinessHoursDay.Monday],
    twilight_weekly_max: 1,

    scan_daily: 4,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 60,
    scan_onsite_2001_3000: 75,
    scan_onsite_3001_4000: 90,
    scan_onsite_4001_5000: 120,
    scan_onsite_5001_6000: 135,

    highlights_onsite: 30,
  },
  [TwtProviderId.BRYAN_MURPHY]: {
    hidden: false,
    email: 'bryan.murphy2@gmail.com',
    first: 'Bryan',
    last: 'Murphy',
    phone: '+15208788124',
    headshot: 'tenant/twt/provider/bryan.webp',
    start_address: {
      line1: '403 Heron Drive',
      city: 'Austin',
      state: 'TX',
      zip: '78734',
      long: -97.9490288,
      lat: 30.3588033,
    },
    schedule_start: '09:00',

    photo_daily: 5,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 45,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 45,
    photo_onsite_5001_6000: 75,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '17:00',
    rush_avoid: true,

    twilight_weekly_max: 3,
    twilight_weekly_ideal: 2,
    twilight_days_confirm: [BusinessHoursDay.Friday],

    scan_daily: 4,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 45,
    scan_onsite_4001_5000: 45,
    scan_onsite_5001_6000: 90,

    highlights_onsite: 30,
    reel_onsite: 60,
    cameo_onsite: 30,

    aerial_onsite_mini: 15,
    aerial_onsite_full: 30,
    aerial_onsite_video: 45,
    aerial_onsite_photo_video: 60,

    video_onsite_0_2000: 75,
    video_onsite_2001_3000: 75,
    video_onsite_over_3000: 75,
  },
  [TwtProviderId.CALEB_RUCKEL]: {
    hidden: false,
    email: 'caleb.ruckel@gmail.com',
    first: 'Caleb',
    last: 'Ruckel',
    phone: '+15129253723',
    headshot: 'tenant/twt/provider/caleb.jpg',
    start_address: {
      line1: '3816 South Lamar Boulevard',
      city: 'Austin',
      state: 'TX',
      zip: '78704',
      long: -97.79098359999999,
      lat: 30.2399155,
    },
    schedule_start: '09:00',

    photo_daily: 5,
    photo_onsite_0_1000: 45,
    photo_onsite_1001_2000: 60,
    photo_onsite_2001_3000: 75,
    photo_onsite_3001_4000: 90,
    photo_onsite_4001_5000: 105,
    photo_onsite_5001_6000: 120,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '16:00',

    twilight_daily_shoots: 2,
    twilight_weekly_max: 3,
    twilight_weekly_ideal: 2,
    twilight_delay: '10:30',

    scan_daily: 5,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 60,
    scan_onsite_3001_4000: 90,
    scan_onsite_4001_5000: 120,
    scan_onsite_5001_6000: 150,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,
    aerial_onsite_mini: 30,
    aerial_onsite_full: 45,
  },
  [TwtProviderId.DANA_RASMUSSEN]: {
    hidden: true,
    email: 'danadamevisuals@gmail.com',
    first: 'Dana',
    last: 'Rasmussen',
    phone: '+19196026022',
    headshot: 'tenant/twt/provider/dana.jpg',
    start_address: {
      line1: '1801 Wells Branch Parkway',
      city: 'Austin',
      state: 'TX',
      zip: '78728',
      long: -97.67420969999999,
      lat: 30.4315572,
      line2: '#1002',
    },

    schedule_start: '09:00',
    schedule_end: '14:00',

    photo_daily: 3,
    photo_onsite_0_1000: 45,
    photo_onsite_1001_2000: 60,
    photo_onsite_2001_3000: 60,
    photo_onsite_3001_4000: 75,
    photo_onsite_4001_5000: 90,
    photo_onsite_5001_6000: 105,
    photo_onsite_every_1000_after_6000: 15,

    rush_avoid: true,
    rush_end_one: '14:00',

    scan_daily: 3,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 75,
    scan_onsite_3001_4000: 75,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 120,

    highlights_onsite: 45,

    aerial_onsite_mini: 30,
    aerial_onsite_full: 30,
    aerial_onsite_video: 60,
    aerial_onsite_photo_video: 90,
    aerial_end_video: '15:00',
    aerial_end_photo: '15:30',
  },
  [TwtProviderId.DEAN_JANKA]: {
    hidden: true,
    email: 'deanjanka@gmail.com',
    first: 'Dean',
    last: 'Janka',
    phone: '+17039096535',
    headshot: 'tenant/twt/provider/dean2.jpg',
    start_address: {
      line1: '1302 Ponderosa Bend',
      city: 'Horseshoe Bay',
      state: 'TX',
      zip: '78657',
      long: -98.3326031,
      lat: 30.53479249999999,
    },
    days: {
      [BusinessHoursDay.Monday]: {},
      [BusinessHoursDay.Tuesday]: {},
      [BusinessHoursDay.Wednesday]: {},
      [BusinessHoursDay.Thursday]: {},
    },

    aerial_onsite_mini: 15,
    aerial_onsite_full: 30,
    aerial_onsite_video: 45,
    aerial_onsite_photo_video: 60,
  },
  [TwtProviderId.JEFF_LOFTIN]: {
    hidden: true,
    email: 'loftinphoto@gmail.com',
    first: 'Jeff',
    last: 'Loftin',
    phone: '+15129445514',
    headshot: 'tenant/twt/provider/jeff.jpg',
    start_address: {
      line1: '10810 Keystone Bend',
      city: 'Austin',
      state: 'TX',
      zip: '78750',
      long: -97.7984929,
      lat: 30.4317839,
    },

    schedule_start: '09:30',

    photo_daily: 5,
    photo_onsite_0_1000: 45,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 60,
    photo_onsite_3001_4000: 75,
    photo_onsite_4001_5000: 90,
    photo_onsite_5001_6000: 105,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:30',

    scan_daily: 3,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 60,
    scan_onsite_3001_4000: 75,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 105,

    highlights_onsite: 45,
  },
  [TwtProviderId.KYLE_GIRGUS]: {
    hidden: true,
    email: 'kyle@kilofoto.com',
    first: 'Kyle',
    phone: '+12103837776',
    headshot: 'tenant/twt/provider/kyle.jpeg',
    last: 'Girgus',
    start_address: {
      line1: '19004 George Norris Drive',
      city: 'Manor',
      state: 'TX',
      zip: '78653',
      long: -97.51955919999999,
      lat: 30.36427329999999,
    },

    schedule_start: '09:00',

    days: {
      [BusinessHoursDay.Monday]: { photo_daily: 4 },
      [BusinessHoursDay.Tuesday]: { photo_daily: 4 },
      [BusinessHoursDay.Wednesday]: { photo_daily: 4 },
      [BusinessHoursDay.Thursday]: { photo_daily: 2, travel_end: '14:30' },
      [BusinessHoursDay.Friday]: { photo_daily: 2, travel_end: '14:30' },
    },

    photo_onsite_0_1000: 15,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 45,
    photo_onsite_5001_6000: 60,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '12:00',

    twilight_days: [BusinessHoursDay.Monday, BusinessHoursDay.Tuesday, BusinessHoursDay.Wednesday],
    twilight_weekly_max: 2,

    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 60,
    scan_onsite_5001_6000: 90,

    highlights_onsite: 30,

    aerial_onsite_mini: 15,
    aerial_onsite_full: 30,
    aerial_onsite_video: 30,
    aerial_onsite_photo_video: 60,

    video_onsite_0_2000: 60,
    video_onsite_2001_3000: 60,
    video_onsite_over_3000: 60,

    reel_onsite: 75,
  },
  [TwtProviderId.LAURA_DUNCAN]: {
    hidden: false,
    email: 'lauramduncan88@gmail.com',
    first: 'Laura',
    last: 'Duncan',
    phone: '+15023389458',
    headshot: 'tenant/twt/provider/laura.jpg',
    start_address: {
      line1: '8204 Talferd Trail',
      city: 'Austin',
      state: 'TX',
      zip: '78744',
      long: -97.7124154,
      lat: 30.1522828,
    },

    schedule_start: '09:00',
    travel_end_ideal: '15:30',

    photo_daily: 5,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 45,
    photo_onsite_3001_4000: 75,
    photo_onsite_4001_5000: 105,
    photo_onsite_5001_6000: 120,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:00',
    rush_end_one_twilight: '14:00',
    rush_end_two: '14:00',

    twilight_days: [BusinessHoursDay.Monday, BusinessHoursDay.Wednesday],
    twilight_days_confirm: [BusinessHoursDay.Tuesday, BusinessHoursDay.Thursday, BusinessHoursDay.Friday],
    twilight_distance: 30,
    twilight_end: '14:30',

    scan_daily: 3,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 60,
    scan_onsite_3001_4000: 90,
    scan_onsite_4001_5000: 120,
    scan_onsite_5001_6000: 135,

    highlights_onsite: 30,

    aerial_onsite_mini: 40,
    aerial_onsite_full: 40,
  },
  [TwtProviderId.LINAS_MILAVICKAS]: {
    hidden: true,
    email: 'linas.milavickas@gmail.com',
    first: 'Linas',
    last: 'Milavickas',
    phone: '+15124206036',
    headshot: 'tenant/twt/provider/linas.jpg',
    start_address: {
      line1: '7506 Corrie Cove',
      city: 'Austin',
      state: 'TX',
      zip: '78749',
      long: -97.877076,
      lat: 30.2274982,
    },

    schedule_start: '09:15',
    travel_end: '16:00',

    photo_daily: 5,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 45,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 60,
    photo_onsite_5001_6000: 75,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:00',

    twilight_daily_shoots: 4,
    twilight_days: [BusinessHoursDay.Tuesday, BusinessHoursDay.Wednesday],
    twilight_days_confirm: [BusinessHoursDay.Monday, BusinessHoursDay.Thursday, BusinessHoursDay.Friday],
    twilight_distance: 30,

    scan_daily: 3,
    scan_ricoh: true,
    scan_onsite_0_1000: 15,
    scan_onsite_1001_2000: 15,
    scan_onsite_2001_3000: 30,
    scan_onsite_3001_4000: 30,
    scan_onsite_4001_5000: 45,
    scan_onsite_5001_6000: 60,

    highlights_onsite: 30,

    aerial_onsite_mini: 15,
    aerial_onsite_full: 30,
  },
  [TwtProviderId.LINN_RIVERS]: {
    hidden: true,
    email: 'evolve@linnrivers.com',
    first: 'Linn',
    last: 'Rivers',
    phone: '+16198773030',
    headshot: 'tenant/twt/provider/linn.jpg',
    start_address: {
      line1: '477 Suffield Drive',
      city: 'Buda',
      state: 'TX',
      zip: '78610',
      long: -97.82676,
      lat: 30.0526155,
    },

    schedule_start: '09:00',

    photo_daily: 4,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 30,
    photo_onsite_4001_5000: 45,
    photo_onsite_5001_6000: 60,
    photo_onsite_every_1000_after_6000: 30,

    rush_end_one: '14:30',

    scan_daily: 4,
    scan_ricoh: true,
    scan_mppro2: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 120,
    scan_onsite_every_1000_after_6000: 30,

    aerial_onsite_mini: 15,
    aerial_onsite_full: 15,
    aerial_onsite_video: 30,
    aerial_onsite_photo_video: 60,
    aerial_confirm_plus_interior: true,
    aerial_confirm_plus_interior_photos: true,

    video_confirm: true,
    video_onsite_0_2000: 30,
    video_onsite_2001_3000: 30,
    video_onsite_over_3000: 30,
  },
  [TwtProviderId.MICHELLE_NOLAN]: {
    hidden: true,
    email: 'annmichellenolan@gmail.com',
    first: 'Michelle',
    last: 'Nolan',
    headshot: 'tenant/twt/provider/michelle.jpg',
    phone: '+15126272708',
    start_address: {
      line1: '11401 Circle Drive',
      city: 'Austin',
      state: 'TX',
      zip: '78748',
      long: -97.8174593,
      lat: 30.1460681,
    },

    days: {
      [BusinessHoursDay.Monday]: {},
      [BusinessHoursDay.Tuesday]: {},
      [BusinessHoursDay.Wednesday]: {},
      [BusinessHoursDay.Thursday]: {},
      [BusinessHoursDay.Friday]: {},
      [BusinessHoursDay.Saturday]: { schedule_end: '12:00', confirm: true },
    },

    schedule_start: '09:00',
    travel_end_ideal: '16:00',
    fill: 4,

    photo_daily: 6,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 60,
    photo_onsite_5001_6000: 75,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:00',

    scan_daily: 3,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 15,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 45,
    scan_onsite_4001_5000: 60,
    scan_onsite_5001_6000: 75,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,
  },
  [TwtProviderId.PHILIP_ROGERS]: {
    hidden: true,
    email: 'philipjrogers3@gmail.com',
    first: 'Philip',
    last: 'Rogers',
    phone: '+12074498593',
    headshot: 'tenant/twt/provider/philip.jpg',
    start_address: {
      line1: '1300 Spyglass Drive',
      city: 'Austin',
      state: 'TX',
      zip: '78746',
      long: -97.7870311,
      lat: 30.2611252,
      line2: '#149',
    },

    schedule_start: '09:30',

    days: {
      [BusinessHoursDay.Monday]: {
        travel_end: '14:00',
      },
      [BusinessHoursDay.Wednesday]: {},
      [BusinessHoursDay.Thursday]: {},
      [BusinessHoursDay.Friday]: {},
    },

    photo_daily: 4,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 45,
    photo_onsite_3001_4000: 60,
    photo_onsite_4001_5000: 75,
    photo_onsite_5001_6000: 90,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:00',

    scan_daily: 2,
    scan_mpcam: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 45,
    scan_onsite_2001_3000: 60,
    scan_onsite_3001_4000: 75,
    scan_onsite_4001_5000: 90,
    scan_onsite_5001_6000: 105,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,

    aerial_onsite_mini: 15,
    aerial_onsite_full: 30,
  },
  [TwtProviderId.RICKY_LANIER]: {
    hidden: true,
    email: 'ricky@roam360photos.com',
    first: 'Ricky',
    last: 'Lanier',
    phone: '+15124154238',
    headshot: 'tenant/twt/provider/ricky.jpg',
    start_address: {
      line1: '16533 Moonlit Path',
      city: 'Manor',
      state: 'TX',
      zip: '78653',
      long: -97.55749709999999,
      lat: 30.2953064,
    },

    schedule_start: '09:00',
    schedule_end: '14:30',

    photo_daily: 4,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 45,
    photo_onsite_2001_3000: 75,
    photo_onsite_3001_4000: 105,
    photo_onsite_4001_5000: 135,
    photo_onsite_5001_6000: 165,
    photo_onsite_every_1000_after_6000: 15,

    rush_end_one: '15:00',
    rush_avoid: true,

    scan_daily: 4,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 60,
    scan_onsite_2001_3000: 75,
    scan_onsite_3001_4000: 90,
    scan_onsite_4001_5000: 120,
    scan_onsite_5001_6000: 135,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,
  },
  [TwtProviderId.TRAVIS_BAKER]: {
    hidden: false,
    email: 'travis.w.baker1@gmail.com',
    first: 'Travis',
    last: 'Baker',
    phone: '+18327682027',
    headshot: 'tenant/twt/provider/travis.jpg',
    start_address: {
      line1: '1012 Brighton Place',
      city: 'Round Rock',
      state: 'TX',
      zip: '78665',
      long: -97.64168319999999,
      lat: 30.52831819999999,
    },

    photo_daily: 6,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 60,
    photo_onsite_5001_6000: 75,
    photo_onsite_every_1000_after_6000: 30,

    twilight_daily_shoots: 5,
    twilight_weekly_max: 2,
    twilight_end: '15:00',
    twilight_postals_excludes: ['78737'],
    twilight_distance: 45,

    rush_end_one: '15:30',

    scan_daily: 2,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 45,
    scan_onsite_1001_2000: 60,
    scan_onsite_2001_3000: 75,
    scan_onsite_3001_4000: 90,
    scan_onsite_4001_5000: 120,
    scan_onsite_5001_6000: 150,
    scan_onsite_every_1000_after_6000: 30,

    highlights_onsite: 30,

    aerial_onsite_mini: 30,
    aerial_onsite_full: 45,
    aerial_onsite_video: 60,
    aerial_onsite_photo_video: 90,

    reel_onsite: 75,
    cameo_onsite: 30,

    video_onsite_0_2000: 90,
    video_onsite_2001_3000: 90,
    video_onsite_over_3000: 90,
  },
  [TwtProviderId.TRENT_HEADY]: {
    hidden: true,
    email: 'trent.heady@gmail.com',
    first: 'Trent',
    last: 'Heady',
    phone: '+12107223244',
    headshot: 'tenant/twt/provider/trent.jpg',
    start_address: {
      line1: '19291 Stone Oak Parkway',
      city: 'San Antonio',
      state: 'TX',
      zip: '78258',
      long: -98.4978203,
      lat: 29.6320671,
    },
    end_address: {
      line1: '15318 Antler Creek Drive',
      city: 'San Antonio',
      state: 'TX',
      zip: '78248',
      long: -98.5235821,
      lat: 29.5866581,
    },

    travel_start: '09:00',
    schedule_start: '09:30',

    photo_daily: 4,
    photo_onsite_0_1000: 30,
    photo_onsite_1001_2000: 30,
    photo_onsite_2001_3000: 30,
    photo_onsite_3001_4000: 45,
    photo_onsite_4001_5000: 60,
    photo_onsite_5001_6000: 75,
    photo_onsite_every_1000_after_6000: 15,

    twilight_days: [BusinessHoursDay.Tuesday, BusinessHoursDay.Wednesday],
    twilight_days_ideal: [BusinessHoursDay.Wednesday],
    twilight_weekly_max: 2,

    cameo_onsite: 30,

    scan_daily: 2,
    scan_mppro2: true,
    scan_ricoh: true,
    scan_onsite_0_1000: 30,
    scan_onsite_1001_2000: 30,
    scan_onsite_2001_3000: 45,
    scan_onsite_3001_4000: 60,
    scan_onsite_4001_5000: 75,
    scan_onsite_5001_6000: 90,
    scan_onsite_every_1000_after_6000: 15,

    highlights_onsite: 30,
    aerial_onsite_mini: 15,
    aerial_onsite_full: 15,
    aerial_onsite_video: 30,
    aerial_onsite_photo_video: 45,

    video_onsite_0_2000: 60,
    video_onsite_2001_3000: 60,
    video_onsite_over_3000: 60,

    reel_onsite: 45,
  },
  // [TwtProviderId.PARIS_HOLLEY]: {
  //   hidden: true,
  //   email: 'mail@parisholley.com',
  //   first: 'Paris',
  //   last: 'Holley',
  //   phone: '+15159749848',
  //   headshot: 'avatar.jpg',
  //   start_address: {
  //     line1: '19291 Stone Oak Parkway',
  //     city: 'San Antonio',
  //     state: 'TX',
  //     zip: '78258',
  //     long: -98.4978203,
  //     lat: 29.6320671,
  //   },
  // },
  // [TwtProviderId.NICK_RUCKEL]: {
  //   first: 'Nick',
  //   last: 'Ruckel',
  //   start_address: {
  //     line1: '241 Sweetwater Creek Lane',
  //     city: 'Hutto',
  //     state: 'TX',
  //     zip: '78634',
  //     long: -97.5361633,
  //     lat: 30.5746713,
  //   },
  // },
};

export const TWT_TRAVEL_FEES: Record<string, { revenue: number; expense: number }> = {
  '76501': { revenue: 70, expense: 56 },
  '76502': { revenue: 70, expense: 56 },
  '76503': { revenue: 80, expense: 64 },
  '76504': { revenue: 90, expense: 72 },
  '76505': { revenue: 80, expense: 64 },
  '76508': { revenue: 70, expense: 56 },
  '76511': { revenue: 40, expense: 32 },
  '76513': { revenue: 50, expense: 40 },
  '76522': { revenue: 100, expense: 80 },
  '76523': { revenue: 70, expense: 56 },
  '76527': { revenue: 30, expense: 24 },
  '76530': { revenue: 40, expense: 32 },
  '76533': { revenue: 80, expense: 64 },
  '76534': { revenue: 60, expense: 48 },
  '76537': { revenue: 30, expense: 24 },
  '76539': { revenue: 100, expense: 80 },
  '76540': { revenue: 80, expense: 64 },
  '76541': { revenue: 80, expense: 64 },
  '76542': { revenue: 90, expense: 72 },
  '76543': { revenue: 110, expense: 88 },
  '76544': { revenue: 100, expense: 80 },
  '76546': { revenue: 80, expense: 64 },
  '76547': { revenue: 70, expense: 56 },
  '76548': { revenue: 70, expense: 56 },
  '76549': { revenue: 70, expense: 56 },
  '76550': { revenue: 120, expense: 96 },
  '76554': { revenue: 70, expense: 56 },
  '76556': { revenue: 90, expense: 72 },
  '76559': { revenue: 60, expense: 48 },
  '76564': { revenue: 90, expense: 72 },
  '76567': { revenue: 60, expense: 48 },
  '76569': { revenue: 100, expense: 80 },
  '76571': { revenue: 40, expense: 32 },
  '76573': { revenue: 30, expense: 24 },
  '76574': { revenue: 25, expense: 20 },
  '76577': { revenue: 50, expense: 40 },
  '76578': { revenue: 30, expense: 24 },
  '76579': { revenue: 100, expense: 80 },
  '78003': { revenue: 50, expense: 40 },
  '78004': { revenue: 40, expense: 32 },
  '78006': { revenue: 30, expense: 24 },
  '78008': { revenue: 40, expense: 32 },
  '78011': { revenue: 90, expense: 72 },
  '78012': { revenue: 90, expense: 72 },
  '78013': { revenue: 30, expense: 24 },
  '78015': { revenue: 20, expense: 16 },
  '78016': { revenue: 20, expense: 16 },
  '78023': { revenue: 15, expense: 12 },
  '78026': { revenue: 30, expense: 24 },
  '78027': { revenue: 40, expense: 32 },
  '78028': { revenue: 85, expense: 68 },
  '78053': { revenue: 40, expense: 32 },
  '78056': { revenue: 30, expense: 24 },
  '78057': { revenue: 40, expense: 32 },
  '78062': { revenue: 40, expense: 32 },
  '78063': { revenue: 30, expense: 24 },
  '78065': { revenue: 20, expense: 16 },
  '78066': { revenue: 20, expense: 16 },
  '78070': { revenue: 20, expense: 16 },
  '78074': { revenue: 30, expense: 24 },
  '78114': { revenue: 20, expense: 16 },
  '78115': { revenue: 20, expense: 16 },
  '78121': { revenue: 20, expense: 16 },
  '78123': { revenue: 20, expense: 16 },
  '78130': { revenue: 25, expense: 20 },
  '78131': { revenue: 25, expense: 20 },
  '78132': { revenue: 30, expense: 24 },
  '78133': { revenue: 80, expense: 64 },
  '78135': { revenue: 25, expense: 20 },
  '78155': { revenue: 20, expense: 16 },
  '78156': { revenue: 20, expense: 16 },
  '78163': { revenue: 15, expense: 12 },
  '78253': { revenue: 15, expense: 12 },
  '78254': { revenue: 15, expense: 12 },
  '78261': { revenue: 15, expense: 12 },
  '78602': { revenue: 25, expense: 20 },
  '78605': { revenue: 40, expense: 32 },
  '78606': { revenue: 40, expense: 32 },
  '78608': { revenue: 40, expense: 32 },
  '78611': { revenue: 60, expense: 48 },
  '78612': { revenue: 20, expense: 16 },
  '78615': { revenue: 15, expense: 12 },
  '78616': { revenue: 30, expense: 24 },
  '78619': { revenue: 15, expense: 12 },
  '78620': { revenue: 20, expense: 16 },
  '78621': { revenue: 20, expense: 16 },
  '78622': { revenue: 30, expense: 24 },
  '78623': { revenue: 50, expense: 40 },
  '78624': { revenue: 85, expense: 68 },
  '78628': { revenue: 15, expense: 12 },
  '78629': { revenue: 40, expense: 32 },
  '78632': { revenue: 90, expense: 72 },
  '78633': { revenue: 18, expense: 14 },
  '78635': { revenue: 90, expense: 72 },
  '78636': { revenue: 85, expense: 68 },
  '78638': { revenue: 30, expense: 24 },
  '78639': { revenue: 120, expense: 96 },
  '78640': { revenue: 15, expense: 12 },
  '78642': { revenue: 30, expense: 24 },
  '78643': { revenue: 75, expense: 60 },
  '78644': { revenue: 25, expense: 20 },
  '78645': { revenue: 25, expense: 20 },
  '78648': { revenue: 35, expense: 28 },
  '78650': { revenue: 20, expense: 16 },
  '78654': { revenue: 65, expense: 52 },
  '78655': { revenue: 30, expense: 24 },
  '78656': { revenue: 30, expense: 24 },
  '78657': { revenue: 75, expense: 60 },
  '78659': { revenue: 30, expense: 24 },
  '78661': { revenue: 30, expense: 24 },
  '78662': { revenue: 40, expense: 32 },
  '78663': { revenue: 60, expense: 48 },
  '78666': { revenue: 25, expense: 20 },
  '78667': { revenue: 25, expense: 20 },
  '78669': { revenue: 25, expense: 20 },
  '78670': { revenue: 30, expense: 24 },
  '78672': { revenue: 190, expense: 152 },
  '78673': { revenue: 25, expense: 20 },
  '78674': { revenue: 25, expense: 20 },
  '78676': { revenue: 30, expense: 24 },
  '78701': { revenue: 15, expense: 12 },
  '78738': { revenue: 20, expense: 16 },
  '78850': { revenue: 100, expense: 80 },
  '78861': { revenue: 40, expense: 32 },
  '78886': { revenue: 40, expense: 32 },
  '78947': { revenue: 40, expense: 32 },
  '78953': { revenue: 60, expense: 48 },
  '78957': { revenue: 30, expense: 24 },
};
