import { registerTenant, TenantId } from '~/tenants/common/registry';
import PicPhotographyForm from '~/tenants/pic/performable/photos/PicPhotographyForm';
import PicPhotographyConfig from '~/tenants/pic/performable/photos/PicPhotographyConfig';
import { PicOrder } from '~/tenants/pic/model/PicOrder';
import Pic3dConfig from '~/tenants/pic/performable/3d/Pic3dConfig';
import Pic3dJob from '~/tenants/pic/performable/3d/Pic3dJob';
import PicAerialConfig from '~/tenants/pic/performable/aerial/PicAerialConfig';
import PicAerialJob from '~/tenants/pic/performable/aerial/PicAerialJob';
import PicBrochuresConfig from '~/tenants/pic/performable/brochures/PicBrochuresConfig';
import PicBrochuresJob from '~/tenants/pic/performable/brochures/PicBrochuresJob';
import PicEddmConfig from '~/tenants/pic/performable/eddm/PicEddmConfig';
import PicEddmJob from '~/tenants/pic/performable/eddm/PicEddmJob';
import PicPhotographyJob from '~/tenants/pic/performable/photos/PicPhotographyJob';
import PicFloorplansConfig from '~/tenants/pic/performable/floorplans/PicFloorplansConfig';
import PicFloorplansJob from '~/tenants/pic/performable/floorplans/PicFloorplansJob';
import PicVirtualStagingConfig from '~/tenants/pic/performable/staging/PicVirtualStagingConfig';
import PicVirtualStagingJob from '~/tenants/pic/performable/staging/PicVirtualStagingJob';
import Pic3dForm from '~/tenants/pic/performable/3d/Pic3dForm';
import PicVirtualStagingForm from '~/tenants/pic/performable/staging/PicVirtualStagingForm';
import PicFloorplansForm from '~/tenants/pic/performable/floorplans/PicFloorplansForm';
import PicEddmForm from '~/tenants/pic/performable/eddm/PicEddmForm';
import PicBrochuresForm from '~/tenants/pic/performable/brochures/PicBrochuresForm';
import PicAerialForm from '~/tenants/pic/performable/aerial/PicAerialForm';
import { TimeZone } from '~/lib/enum';
import PicProvider from '~/tenants/pic/model/PicProvider';
import PicOrderWizardType from '~/tenants/pic/forms/PicOrderWizardType';
import PicOrderForm from '~/tenants/pic/forms/PicOrderForm';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import { Pic_LOCATION, Pic_PROVIDER_A, Pic_PROVIDER_B } from '~/tenants/pic/vars';
import { GeocodedAddressExample } from '~/lib/model';
import PicCustomer from '~/tenants/pic/model/PicCustomer';
import { PicOrderSchema } from '~/tenants/pic/model/PicOrder';

export default function registerPic() {
  registerTenant(TenantId.PLUSH, PicOrderSchema, {
    name: 'PLUSH Media',
    legal: 'PLUSH Media Corporation',
    background: '#55aaee',
    domain: 'pic.photology.co',
    email: 'pic@photology.co',
    markaEnable: true,
    scheduleStartTime: '08:00',
    scheduleEndTime: '20:00',
    logoRaster: 'https://optimize.photology.co/tenant/pic/logo.png',
    customerClass: PicCustomer,
    providers: {
      [Pic_PROVIDER_A]: {
        first: 'Provider',
        last: 'A',
        headshot: 'avatar.jpg',
        phone: '+15159749848',
        email: 'providera@f.com',
        start_address: GeocodedAddressExample,
      },
      [Pic_PROVIDER_B]: {
        first: 'Provider',
        last: 'B',
        headshot: 'avatar.jpg',
        phone: '+15159749848',
        email: 'providerb@f.com',
        start_address: GeocodedAddressExample,
      },
    },
    providerClass: PicProvider,
    // TODO: make typescript happy
    orderClass: PicOrder as any,
    locations: [
      {
        timezone: TimeZone.US_MOUNTAIN,
        slug: Pic_LOCATION,
        name: 'Denver',
        address: {
          line1: '1714 N Mascher St, Philadelphia, PA 19122',
          line2: null,
          city: 'Philadelphia',
          state: 'PA',
          zip: '19122',
          long: -75.13619526666666,
          lat: 39.976185533333336,
        },
      },
    ],
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },

    forms: {
      wizardType: PicOrderWizardType,
      orderConfigure: PicOrderForm,
      provider: EmptyForm,
    },

    packages: [],
    performables: {
      [PicPhotographyConfig.id]: {
        config: PicPhotographyConfig,
        form: PicPhotographyForm,
        job: PicPhotographyJob,
      },
      [PicFloorplansConfig.id]: {
        config: PicFloorplansConfig,
        form: PicFloorplansForm,
        job: PicFloorplansJob,
      },
      [Pic3dConfig.id]: {
        config: Pic3dConfig,
        form: Pic3dForm,
        job: Pic3dJob,
      },
      [PicAerialConfig.id]: {
        config: PicAerialConfig,
        form: PicAerialForm,
        job: PicAerialJob,
      },
      [PicVirtualStagingConfig.id]: {
        config: PicVirtualStagingConfig,
        form: PicVirtualStagingForm,
        job: PicVirtualStagingJob,
      },
      // [PicBrochuresConfig.id]: {
      //   config: PicBrochuresConfig,
      //   form: PicBrochuresForm,
      //   job: PicBrochuresJob,
      // },
      // [PicEddmConfig.id]: {
      //   config: PicEddmConfig,
      //   form: PicEddmForm,
      //   job: PicEddmJob,
      // },
    },
  });
}
