import React from 'react';
import {
  TwtPackage3dInclude,
  TwtPackageFloorplanAddOns,
  TwtPackageFloorplanInclude,
  TwtPackagePhotoAddOns,
  TwtPackagePhotoInclude,
} from '~/tenants/twt/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import TwtMatterportConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { PackageFormNested } from '~/components/performable/PackageForm';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TwtOrderType } from '~/tenants/twt/model/TwtOrder';

export default function TwtMatterportTourForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TwtPackagePhotoInclude />
        <TwtPackageFloorplanInclude />
        <TwtPackage3dInclude />
      </TenantPackageIncludes>
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <PackageFormNested performable={TwtMatterportConfig}>
          <ZodFieldHidden name="floors" value="1" />
        </PackageFormNested>
        <TwtPackagePhotoAddOns type={TwtOrderType.FOR_SALE_LISTING} />
        <TwtPackageFloorplanAddOns />
      </TenantPerformableAddOns>
    </>
  );
}
