import { TenantPackageAddOnGallerySingleCheckbox } from '~/tenants/common/package';
import React from 'react';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TWT_VIRTUAL_PRICE } from '~/tenants/twt/performable/virtual/TwtVirtualJob';
import FormGroup from '~/components/form/FormGroup';
import ResponsiveImage from '~/components/ResponsiveImage';
import { useZodFormFieldSingle, useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import classNames from 'classnames';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';

export default function TwtVirtualForm({ persona }: TenantPerformableFormProps<TwtOrderContext>) {
  const [_sky, sky, setSky] = useZodFormFieldSingle('sky');

  const images = [
    'tenant/twt/twilight/virtual/1.tif',
    'tenant/twt/twilight/virtual/2.tif',
    'tenant/twt/twilight/virtual/3.tif',
    'tenant/twt/twilight/virtual/4.tif',
    'tenant/twt/twilight/virtual/5.tif',
    'tenant/twt/twilight/virtual/10.tif',
  ];

  return (
    <FormGroup>
      <div className="border-theme-border border rounded-theme p-4 space-y-2">
        <div className="text-sm font-medium">Choose a sky:</div>
        <div className="grid grid-cols-3 gap-2">
          {images.map((image, index) => (
            <div key={image}>
              <ResponsiveImage
                path={image}
                onClick={() => (sky === image ? setSky(undefined) : setSky(image))}
                className={classNames('cursor-pointer hover:scale-105 transition rounded-theme w-full', {
                  'ring-4 ring-green-300': sky === image,
                  'opacity-50 hover:opacity-100': sky && sky !== image,
                })}
                width={150}
                height={100}
              />
            </div>
          ))}
        </div>
      </div>
      <ZodFieldHidden name="sky" value={sky} />
      <FormHorizontal label="# of Photos">
        <ZodFieldInput type="number" name="photos" />
      </FormHorizontal>
    </FormGroup>
  );
}
