import { LoaderFunctionArgs, redirect } from '@remix-run/cloudflare';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useLoaderData,
} from '@remix-run/react';
import { TenantContextProvider } from './tenants/common/TenantContextProvider';
import { registerTenants } from './tenants/register';
import { getTenant } from '~/tenants/common/registry';
import { tenant_require } from '~/tenants/common/util.server';
import { PortalHolder } from '~/components/interactive/Portals';
import { IntlProvider } from 'react-intl';
import stylesheet from '~/tailwind.css?url';
import { useEffect } from 'react';
import { rainforest_is_production } from '~/.server/rainforest';

registerTenants();

export function loader(args: LoaderFunctionArgs) {
  const url = new URL(args.request.url);

  if (args.context.env.IS_CLOUDFLARE && args.request.headers.get('X-Forwarded-Proto') !== 'https') {
    url.protocol = 'https';

    return redirect(url.toString(), { status: 301 });
  }

  const tenant = tenant_require(args);

  return {
    tenantId: tenant.id,
    tenantName: tenant.name,
    rainforest_production: rainforest_is_production(args),
    meili_key: args.context.env.MEILISEARCH_SEARCH_KEY ?? '',
    meili_url: args.context.env.MEILISEARCH_URL ?? '',
    meili_prefix: args.context.env.MEILISEARCH_PREFIX ? args.context.env.MEILISEARCH_PREFIX : tenant.id,
  };
}

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

function App() {
  const { tenantId, rainforest_production, ...config } = useLoaderData<typeof loader>();

  const tenant = getTenant(tenantId);

  useEffect(() => {
    if (typeof window !== 'undefined' && tenant.background) {
      document.documentElement.style.setProperty('--tenant', tenant.background);
    }
  }, []);

  return (
    <html lang="en" dir="ltr" className="bg-theme-body body">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
        <Meta />
        <Links />
        <link rel="stylesheet" href={stylesheet} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/tenants/${tenantId}/favicon/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/tenants/${tenantId}/favicon/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/tenants/${tenantId}/favicon/favicon-16x16.png`} />
        <link rel="manifest" href={`/tenants/${tenantId}/favicon/site.webmanifest`} />
        <link rel="mask-icon" href={`/tenants/${tenantId}/favicon/safari-pinned-tab.svg`} color={tenant.background} />
        <link rel="shortcut icon" href={`/tenants/${tenantId}/favicon/favicon.ico`} />
        <meta name="msapplication-TileColor" content={tenant.background} />
        <meta name="msapplication-config" content="/tenants/nep/favicon/browserconfig.xml" />
        {/*<meta name="theme-color" content="#f9fafb" />*/}
        {/*<meta name="theme-color" content={tenant.background} />*/}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        {rainforest_production ? (
          <>
            <script type="module" src="https://static.rainforestpay.com/payment.js" />
            <script type="module" src="https://static.rainforestpay.com/merchant.js" />
          </>
        ) : (
          <>
            <script type="module" src="https://static.rainforestpay.com/sandbox.payment.js" />
            <script type="module" src="https://static.rainforestpay.com/sandbox.merchant.js" />
          </>
        )}
      </head>
      <body className="tracking-normal text-gray-900 body overflow-x-hidden" id="root">
        <IntlProvider locale="en">
          <TenantContextProvider tenant={{ ...tenant, ...config }}>
            <Outlet />
          </TenantContextProvider>
        </IntlProvider>
        <ScrollRestoration />
        <Scripts />
        <PortalHolder />
      </body>
    </html>
  );
}

export default function () {
  const data = useLoaderData<typeof loader>();

  if ('tenantId' in data) {
    // TODO: how to fix typing
    return <App />;
  }

  // microsite support
  return <Outlet />;
}
