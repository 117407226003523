import { TenantPackageAddOnNeighborhoods, TenantPackageGroup } from '~/tenants/common/package';
import { TWT_PHOTO_NEIGHBORHOOD } from '~/tenants/twt/performable/photo/TwtPhotoJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { useEffect, useState } from 'react';
import range from 'lodash.range';

export default function TwtPhotosNeighborhood({ persona }: { persona: PersonaType }) {
  const [neighborhoods, setNeighborhoods] = useState<TenantPackageGroup[]>([]);

  useEffect(() => {
    import('~/tenants/twt/performable/photo/neighborhoods').then((neighborhoods) => {
      setNeighborhoods(
        neighborhoods.default.map((n) => ({
          name: n.name,
          images: range(1, n.files).map((i) => `${n.folder}/${i}.jpg`),
        })),
      );
    });
  }, []);

  return (
    <div>
      <TenantPackageAddOnNeighborhoods
        name="neighborhood"
        title="Stock Neighborhood Amenities"
        image="tenant/twt/neighborhood/1.jpg"
        groups={neighborhoods}
        persona={persona}
        cost={TWT_PHOTO_NEIGHBORHOOD}
        description={
          'Want to market the area lifestyle?\nWe will upload amenity stock photos from our extensive stock image library.\n*the $35 license fee allows you to reuse the photos for future listings'
        }
      />
    </div>
  );
}

const GROUPS: [TenantPackageGroup, ...TenantPackageGroup[]] = [
  {
    name: '78003 - Bridlegate',
    images: [
      'tenant/twt/neighborhood/78003/bridlegate/1.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/2.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/3.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/4.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/5.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/6.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/7.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/8.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/9.jpg',
      'tenant/twt/neighborhood/78003/bridlegate/10.jpg',
    ],
  },
  {
    name: '78006 - Esperanza',
    images: [
      'tenant/twt/neighborhood/78006/esperanza/1.jpg',
      'tenant/twt/neighborhood/78006/esperanza/2.jpg',
      'tenant/twt/neighborhood/78006/esperanza/3.jpg',
      'tenant/twt/neighborhood/78006/esperanza/4.jpg',
      'tenant/twt/neighborhood/78006/esperanza/5.jpg',
      'tenant/twt/neighborhood/78006/esperanza/6.jpg',
      'tenant/twt/neighborhood/78006/esperanza/7.jpg',
      'tenant/twt/neighborhood/78006/esperanza/8.jpg',
    ],
  },
  {
    name: '78015 - Fallbrook',
    images: [
      'tenant/twt/neighborhood/78015/fallbrook/1.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/2.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/3.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/4.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/5.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/6.jpg',
      'tenant/twt/neighborhood/78015/fallbrook/7.jpg',
    ],
  },
];
