import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import { TwtOrderContext, TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';

const TwtMatterportTourConfig: TenantPackageConfig = {
  id: '3d',
  name: 'Photos + Ploor Plan + 3D Walkthrough Tour',
  description:
    'Market a property with HDR photography, a stylized floor plan, and a three dimensional virtual tour all organized into a customized microsite.',
  performables: [TwtPhotoConfig, TwtFloorplanConfig, Twt3dConfig],
  eligible: (context) =>
    (context as TwtOrderContext).metadata.type === TwtOrderType.FOR_SALE_LISTING &&
    (context as TwtOrderContext).metadata.subtype !== TwtPropertyType.LAND,
};

export default TwtMatterportTourConfig;
