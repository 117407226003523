import { registerTenant, TenantId } from '~/tenants/common/registry';
import DemoPhotographyForm from '~/tenants/demo/performable/photos/DemoPhotographyForm';
import DemoPhotographyConfig from '~/tenants/demo/performable/photos/DemoPhotographyConfig';
import { DemoOrder, DemoOrderSchema } from '~/tenants/demo/model/DemoOrder';
import Demo3dConfig from '~/tenants/demo/performable/3d/Demo3dConfig';
import Demo3dJob from '~/tenants/demo/performable/3d/Demo3dJob';
import DemoAerialConfig from '~/tenants/demo/performable/aerial/DemoAerialConfig';
import DemoAerialJob from '~/tenants/demo/performable/aerial/DemoAerialJob';
import DemoBrochuresConfig from '~/tenants/demo/performable/brochures/DemoBrochuresConfig';
import DemoBrochuresJob from '~/tenants/demo/performable/brochures/DemoBrochuresJob';
import DemoEddmConfig from '~/tenants/demo/performable/eddm/DemoEddmConfig';
import DemoEddmJob from '~/tenants/demo/performable/eddm/DemoEddmJob';
import DemoPhotographyJob from '~/tenants/demo/performable/photos/DemoPhotographyJob';
import DemoFloorplansConfig from '~/tenants/demo/performable/floorplans/DemoFloorplansConfig';
import DemoFloorplansJob from '~/tenants/demo/performable/floorplans/DemoFloorplansJob';
import DemoVirtualStagingConfig from '~/tenants/demo/performable/staging/DemoVirtualStagingConfig';
import DemoVirtualStagingJob from '~/tenants/demo/performable/staging/DemoVirtualStagingJob';
import Demo3dForm from '~/tenants/demo/performable/3d/Demo3dForm';
import DemoVirtualStagingForm from '~/tenants/demo/performable/staging/DemoVirtualStagingForm';
import DemoFloorplansForm from '~/tenants/demo/performable/floorplans/DemoFloorplansForm';
import DemoEddmForm from '~/tenants/demo/performable/eddm/DemoEddmForm';
import DemoBrochuresForm from '~/tenants/demo/performable/brochures/DemoBrochuresForm';
import DemoAerialForm from '~/tenants/demo/performable/aerial/DemoAerialForm';
import { TimeZone } from '~/lib/enum';
import DemoProvider from '~/tenants/demo/model/DemoProvider';
import DemoPackageBasicConfig from '~/tenants/demo/package/basic/DemoPackageBasicConfig';
import DemoPackageBasicForm from '~/tenants/demo/package/basic/DemoPackageBasicForm';
import DemoPackageStandardForm from '~/tenants/demo/package/standard/DemoPackageStandardForm';
import DemoPackageStandardConfig from '~/tenants/demo/package/standard/DemoPackageStandardConfig';
import DemoPackagePremiumConfig from '~/tenants/demo/package/premium/DemoPackagePremiumConfig';
import DemoPackagePremiumForm from '~/tenants/demo/package/premium/DemoPackagePremiumForm';
import DemoOrderWizardType from '~/tenants/demo/forms/DemoOrderWizardType';
import DemoOrderForm from '~/tenants/demo/forms/DemoOrderForm';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import { DEMO_LOCATION, DEMO_PROVIDER_A, DEMO_PROVIDER_B } from '~/tenants/demo/vars';
import { GeocodedAddressExample } from '~/lib/model';
import DemoCustomer from '~/tenants/demo/model/DemoCustomer';

export default function registerDemo() {
  registerTenant(TenantId.DEMO, DemoOrderSchema, {
    name: 'Demo Photos',
    legal: 'Demo Photos, Inc.',
    background: '#EC1D24',
    domain: 'demo.photology.co',
    email: 'demo@photology.co',
    nylasGrantId: '9039251d-0270-4ca7-a2e1-cb0a3f8d61af',
    api: 'fa1f1717-68e9-4635-aa64-29cf8c82a5d2',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    logoRaster: 'https://demo.photology.co/media/public/camera.png',
    logoVector: 'https://demo.photology.co/media/public/camera.svg',
    twilioKeySid: 'SK89bc6a34cc16abe21155af6cc5661c31',
    twilioKeySecret: '0BUNW7Fkcmv799GCrV93IH2hK3bfKtZK',
    twilioAccountSid: 'ACca2a0df566597eee270757ea1cb02459',
    twilioMessageServiceSid: 'MG49807da56087fbe3ea804c3f148874a4',
    twilioPhoneNumber: '+17209999667',
    orderNumberMinimum: 50000,
    orderNumberPadding: 7,
    customerClass: DemoCustomer,
    providers: {
      [DEMO_PROVIDER_A]: {
        first: 'Provider',
        last: 'A',
        headshot: 'avatar.jpg',
        phone: '+15159749848',
        email: 'providera@f.com',
        start_address: GeocodedAddressExample,
      },
      [DEMO_PROVIDER_B]: {
        first: 'Provider',
        last: 'B',
        headshot: 'avatar.jpg',
        phone: '+15159749848',
        email: 'providerb@f.com',
        start_address: GeocodedAddressExample,
      },
    },
    providerClass: DemoProvider,
    // TODO: make typescript happy
    orderClass: DemoOrder as any,
    locations: [
      {
        timezone: TimeZone.US_MOUNTAIN,
        slug: DEMO_LOCATION,
        name: 'Denver',
        address: {
          line1: '1980 18th St',
          line2: null,
          city: 'Denver',
          state: 'CO',
          zip: '80202',
          long: -105.0009521,
          lat: 39.7555128,
        },
      },
    ],
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },

    forms: {
      wizardType: DemoOrderWizardType,
      orderConfigure: DemoOrderForm,
      provider: EmptyForm,
    },

    packages: [
      {
        config: DemoPackageBasicConfig,
        form: DemoPackageBasicForm,
      },
      {
        config: DemoPackageStandardConfig,
        form: DemoPackageStandardForm,
      },
      {
        config: DemoPackagePremiumConfig,
        form: DemoPackagePremiumForm,
      },
    ],
    performables: {
      [DemoPhotographyConfig.id]: {
        config: DemoPhotographyConfig,
        form: DemoPhotographyForm,
        job: DemoPhotographyJob,
      },
      [DemoFloorplansConfig.id]: {
        config: DemoFloorplansConfig,
        form: DemoFloorplansForm,
        job: DemoFloorplansJob,
      },
      [Demo3dConfig.id]: {
        config: Demo3dConfig,
        form: Demo3dForm,
        job: Demo3dJob,
      },
      [DemoAerialConfig.id]: {
        config: DemoAerialConfig,
        form: DemoAerialForm,
        job: DemoAerialJob,
      },
      [DemoVirtualStagingConfig.id]: {
        config: DemoVirtualStagingConfig,
        form: DemoVirtualStagingForm,
        job: DemoVirtualStagingJob,
      },
      [DemoBrochuresConfig.id]: {
        config: DemoBrochuresConfig,
        form: DemoBrochuresForm,
        job: DemoBrochuresJob,
      },
      [DemoEddmConfig.id]: {
        config: DemoEddmConfig,
        form: DemoEddmForm,
        job: DemoEddmJob,
      },
    },
  });
}
