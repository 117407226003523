import { ProviderStaticConfig } from '~/lib/model';
import { MpiProviderId, MpiProviderStaticData } from '~/tenants/mpi/model/MpiProvider';

export const MPI_PROVIDER_DATA: Record<MpiProviderId, MpiProviderStaticData & ProviderStaticConfig> = {
  // [MpiProviderId.DANIELLE_BOLLING]: {
  //   first: 'Danielle',
  //   last: 'Bolling',
  //   email: 'nik@mpi360.com',
  //   phone: '+14107181155',
  //   color: '#B4C79B',
  //   headshot: 'avatar.jpg',
  //   start_address: {
  //     line1: '7808 River Rock Way',
  //     lat: 39.1853025,
  //     long: -76.89289029999999,
  //     city: 'Columbia',
  //     state: 'MD',
  //     zip: '21044'
  //   },
  // },
  [MpiProviderId.CHAY_DEAVER]: {
    first: 'Chay',
    last: 'Deaver',
    email: 'info@property-vue.com',
    phone: '+12403207618',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: "301 Carol's Place",
      lat: 38.52565,
      long: -76.979271,
      city: 'La Plata',
      state: 'MD',
      zip: '20646',
    },
  },
  [MpiProviderId.BRAYLAN_DODSON]: {
    first: 'Braylan',
    last: 'Dodson',
    email: 'braylan1005@gmail.com',
    phone: '+14436020167',
    color: '#2EC28E',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '105 Village Way',
      lat: 39.380928,
      long: -77.16120099999999,
      city: 'Mount Airy',
      state: 'MD',
      zip: '21771',
    },
  },
  [MpiProviderId.STEPHEN_POSKO]: {
    first: 'Stephen',
    last: 'Posko',
    email: 'posko84@gmail.com',
    phone: '+14102180273',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '719 South Montford Avenue',
      lat: 39.2835408,
      long: -76.58251709999999,
      city: 'Baltimore',
      state: 'MD',
      zip: '21224',
    },
  },
  [MpiProviderId.SOPHIA_BEDNARIK]: {
    first: 'Sophia',
    last: 'Bednarik',
    email: 'sophia@mpi360.com',
    phone: '+14435373291',
    color: '#FFEC99',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '7808 River Rock Way',
      lat: 39.1853025,
      long: -76.89289029999999,
      city: 'Columbia',
      state: 'MD',
      zip: '21044',
    },
  },
  [MpiProviderId.NICK_KIPKE]: {
    first: 'Nick',
    last: 'Kipke',
    email: 'mldavis1075@gmail.com',
    phone: '+12406875370',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: 'Baltimore, MD 21234, USA',
      long: -76.5372625,
      lat: 39.3909858,
      state: 'MD',
      city: 'Baltimore',
      zip: '21234',
    },
  },
  [MpiProviderId.ALEX_GREGORY]: {
    first: 'Alexandra',
    last: 'Gregory',
    email: 'elysianphotography00@gmail.com',
    phone: '+12407121137',
    color: '#CDB0D8',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '13503 Villadest Drive',
      lat: 39.1885429,
      long: -76.9887305,
      city: 'Highland',
      state: 'MD',
      zip: '20777',
    },
  },
  [MpiProviderId.BRIAN_WOODS]: {
    first: 'Brian',
    last: 'Woods',
    email: 'btwimagesllc@gmail.com',
    phone: '+17033406383',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '5600 General Washington Drive',
      lat: 38.7995289,
      long: -77.163507,
      city: 'Alexandria',
      state: 'VA',
      zip: '22312',
    },
  },
  [MpiProviderId.DAN_WEINEL]: {
    first: 'Dan',
    last: 'Weinel',
    email: 'dan.weinel@gmail.com',
    phone: '+14436172259',
    color: '#A0DAE0',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '1842 Midsummer Lane',
      lat: 39.6475886,
      long: -76.46723399999999,
      city: 'Jarrettsville',
      state: 'MD',
      zip: '21084',
    },
  },
  [MpiProviderId.NIK_TYLER]: {
    first: 'Nik',
    last: 'Tyler',
    email: 'nik@mpi360.com',
    phone: '+14107181155',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '7808 River Rock Way',
      lat: 39.1853025,
      long: -76.89289029999999,
      city: 'Columbia',
      state: 'MD',
      zip: '21044',
    },
  },
  [MpiProviderId.HOULTON_MAHANEY]: {
    first: 'Houlton',
    last: 'Mahaney',
    email: 'houlton@icloud.com',
    phone: '+18088967589',
    color: '#3E7EC7',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '830 South Luzerne Avenue',
      lat: 39.2822434,
      long: -76.5800306,
      city: 'Baltimore',
      state: 'MD',
      zip: '21224',
    },
  },
  [MpiProviderId.TIFFANY_LANG]: {
    first: 'Tiffany',
    last: 'Roschy-Lang',
    email: 'tiff@mpi360.com',
    phone: '+14107076825',
    color: '#7CB0B6',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '1010 Downton Road',
      city: 'Halethorpe',
      zip: '21227',
      state: 'MD',
      lat: 39.24105309999999,
      long: -76.7052468,
    },
  },
  [MpiProviderId.MIKE_DAVIS]: {
    first: 'Tiffany',
    last: 'Roschy-Lang',
    email: 'mldavis1075@gmail.com',
    phone: '+12406875370',
    color: '#B4C79B',
    headshot: 'avatar.jpg',
    start_address: {
      line1: '5285 Shawnee Road',
      line2: '575c',
      long: -77.1649628,
      lat: 38.8086452,
      state: 'VA',
      city: 'Alexandria',
      zip: '22312',
    },
  },
  // [MpiProviderId.TJ_DARPINO]: {
  //   first: 'TJ',
  //   last: 'Darpino',
  //   email: 'btwimagesllc@gmail.com',
  //   phone: '+17033406383',
  //   color: '#B4C79B',
  //   headshot: 'avatar.jpg',
  //   start_address: {
  //     line1: '5600 General Washington Drive',
  //     lat: 38.7995289,
  //     long: -77.163507,
  //     city: 'Alexandria',
  //     state: 'VA',
  //     zip: '22312',
  //   },
  // },
};
